.submitting-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    &__title {
        font-size: 18px;
        font-weight: 700;
    }

    .lds-css {
        position: unset;
        left: unset;
        margin-left: unset;
        top: unset;
        margin-top: unset;
        width: 20px;
        height: 20px;
        border: 3px solid;
    }
}
