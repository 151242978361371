@use 'breakpoints' as breakpoints;
@use 'mixins' as mixins;

.boost-onboarding-report {
    &__icons {
        .boost-onboarding-report__img {
            position: absolute;
            width: 320px;
            left: 50px;
            top: 25px;
            @include mixins.boxShadow(0, 0, 6px, 0, 0, 0, 0, 0.16);
            @include mixins.border-radius(4px);

            @media (max-width: breakpoints.$screen-xbs-max) {
                width: 170px;
                left: 65px;
            }
        }

        .boost-onboarding-report__bell {
            position: absolute;

            &--postion0 {
                top: 50px;
                left: 5px;

                @media (max-width: breakpoints.$screen-xbs-max) {
                    width: 15px;
                    left: 25px;
                }

                img {
                    transform: rotate(5deg);
                    width: 20px;

                    @media (max-width: breakpoints.$screen-xbs-max) {
                        width: 15px;
                    }
                }
            }

            &--postion1 {
                top: 150px;
                left: -35px;

                @media (max-width: breakpoints.$screen-xbs-max) {
                    left: 5px;
                    top: 100px;
                }

                img {
                    width: 60px;
                    transform: rotate(335deg);

                    @media (max-width: breakpoints.$screen-xbs-max) {
                        width: 41px;
                    }
                }
            }

            &--postion2 {
                left: 390px;
                top: 20px;

                @media (max-width: breakpoints.$screen-xbs-max) {
                    left: 255px;
                    top: 35px;
                }

                img {
                    transform: rotate(25deg);
                    width: 62px;

                    @media (max-width: breakpoints.$screen-xbs-max) {
                        width: 34px;
                    }
                }
            }

            &--postion3 {
                left: 405px;
                top: 160px;

                @media (max-width: breakpoints.$screen-xbs-max) {
                    left: 260px;
                    top: 115px;
                }

                img {
                    transform: rotate(345deg);
                    width: 20px;

                    @media (max-width: breakpoints.$screen-xbs-max) {
                        width: 15px;
                    }
                }
            }
        }
    }
}
