@use 'breakpoints' as breakpoints;

main.upgrade-page {
    .page-title {
        h1 {
            margin-bottom: 0;
        }

        h4 {
            margin: 6px 0 30px;
            line-height: 24px;
        }
    }

    &.baby-premium {
        background: url('../img/red-circles-illustration.svg') right -610px top 250px no-repeat;

        @media (min-width: breakpoints.$screen-sm-min) {
            background-position: right -450px top -540px;
        }

        .plan-box h1 {
            padding-bottom: 20px;
        }
    }

    .main-button {
        border-radius: 4px;
        background-color: var(--mp-red);
        display: inline-block;
        text-align: center;
        padding: 15px 8px;
        color: #FFF;
        text-decoration: none;
        font-weight: 700;
        font-size: 18px;
        box-shadow: 0 8px 10px 0 rgba(0, 0, 0, .2);
        transition: background-color 200ms, color 200ms;

        &:hover {
            background-color: var(--mp-red-lighter);
        }

        &:active {
            background-color: var(--mp-red-hover);
        }
    }

    .plan-box {
        border-radius: 10px;
        background-color: #FFF;
        padding: 4px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, .25);

        .featuring-list {
            margin: 20px 11px;

            li {
                padding: 6px 0;
                border-bottom: 1px solid #DDD;
                position: relative;
                width: 100%;
                display: flex;
                align-items: flex-start;

                img {
                    width: 24px;
                    margin-right: 6px;
                }

                &:last-child {
                    border: none;
                    padding-bottom: 0;
                }
            }

            @media (min-width: breakpoints.$screen-sm-min) {
                margin: 30px 0 0;
            }
        }

        &.black {
            background-color: #111;
            color: #FFF;

            .plan-selector {
                background-color: #2D2D2D;

                .item {
                    color: #F3F3F3;
                    &:not(.active) {
                        &:hover {
                            background-color: rgba(238, 238, 238, .06);
                        }
                    }
                }
            }

            .price-container,
            .payment-container {
                background: #202020;
            }

            .featuring-list {
                li {
                    border-color: #444;
                }
            }
        }

        h1 {
            margin: 20px 0 0;
            font-size: 24px;
            text-align: center;

            @media (min-width: breakpoints.$screen-md-min) {
                margin: 0;
                font-size: 26px;
            }
        }

        > p {
            text-align: center;
            margin: 4px 11px 0;
            padding-bottom: 10px;

            @media (min-width: breakpoints.$screen-md-min) {
                padding-bottom: 25px;
            }
        }

        .payment-container {
            background: #F6F6F6;
            text-align: center;
            border-radius: 0 0 10px 10px;
            font-size: 22px;
            line-height: 22px;
            padding-bottom: 30px;

            .main-button {
                display: block;
                width: 260px;
                margin: 30px auto 0;
            }
        }

        .featuring-sbp {
            margin: 20px 11px 30px;
        }

        @media (min-width: breakpoints.$screen-md-min) {
            padding: 30px;
        }
    }

    .plan-options {
        display: flex;
        position: relative;
        border-radius: 10px 10px 0 0;
        margin: 24px 0 0;
        min-height: 60px;

        .body-top {
            text-align: right;
            margin-right: 5px;
            line-height: 0.3;

            @media (max-width: breakpoints.$screen-md-min) {
                display: flex;
            }
        }

        @media (max-width: breakpoints.$screen-sm-min) {
            display: block;
            width: 100%;
        }

        .plan-container {
            width: 100%;
            height: 270px;
            border-radius: 10px;
            border: none;
            cursor: pointer;
            line-height: 18px;
            font-weight: 700;
            transition: background-color 200ms, color 200ms;
            padding: 0 0 10px 0;
            margin: 0 10px;
            text-align: center;

            @media (max-width: breakpoints.$screen-sm-min) {
                width: auto;
                margin-bottom: 10px;
                display: flex;
                min-height: 150px;
                line-height: 0.7;
                height: 220px;
            }

            &:not(.active) {
                background-color: rgb(238, 238, 238);

                @media (max-width: breakpoints.$screen-sm-min) {
                    background: none;
                }

                &:hover {
                    background-color: rgba(238, 238, 238, .8);
                }
            }

            .plan-header {
                padding: 10px;
                border-radius: 10px;
                background-color: #E9E9E9;
                font-size: 26px;

                @media (max-width: breakpoints.$screen-sm-min) {
                    width: 75px;
                }

                div {
                    @media (max-width: breakpoints.$screen-sm-min) {
                        transform: rotate(270deg) translateX(-50px) translateY(-40px);
                        width: 200px;
                        height: 90px;
                    }
                }

                p {
                    margin: 0;
                }

                span {
                    font-size: 13px;
                    font-weight: 400;
                }
            }

            .plan-body {
                display: block;
                padding: 10px;
                font-size: 50px;
                line-height: 0.7;

                &__content {
                    &__data {
                        .prize-title::before {
                            content: '\A';
                            white-space: pre;
                        }
                    }
                }

                @media (max-width: breakpoints.$screen-sm-min) {
                    width: 100%;
                    border-radius: 12px;
                    margin-left: 5px;
                    background-color: rgb(238, 238, 238);
                    display: flex;

                    &__content {
                        margin: auto;
                        width: 100%;

                        &__data {
                            height: 100px;
                            line-height: 100px;

                            p {
                                line-height: 0.8;
                                display: inline-block;
                                vertical-align: middle;
                            }
                        }

                        &__footer {
                            margin: 20px 0 20px;
                        }
                    }
                }

                @media (min-width: breakpoints.$screen-sm-min) {
                    padding: 0;

                    &__content {
                        &__data {
                            height: 130px;
                            line-height: 115px;

                            p {
                                line-height: 0.8;
                                display: inline-block;
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }

            &.black {
                background-color: rgb(45, 45, 45);
                color: #F3F3F3;

                @media (max-width: breakpoints.$screen-sm-min) {
                    background: none;

                    .plan-body {
                        background: rgb(45, 45, 45) none;
                    }
                }

                &:not(.active) {
                    &:hover {
                        background-color: rgba(45, 45, 45, .9);
                    }
                }

                .plan-header {
                    background-color: #555555;
                    color: #F3F3F3;
                }

                .main-button {
                    background-color: #E00C00;
                    color: #F3F3F3;
                }
            }

            .main-button {
                background-color: transparent;
                color: #E00C00;

                @media (max-width: breakpoints.$screen-sm-min) {
                    margin-top: 10px;
                    width: 100%;
                }

                &:hover {
                    background-color: #E00C00;
                    color: #F3F3F3;
                }
            }
        }
    }

    .single-package-description {
        display: flex;
        padding: 5px;
        text-align: left;
        font-size: 14px;

        @media (max-width: breakpoints.$screen-sm-min) {
            display: block;
            width: 100%;
        }

        h1 {
            text-align: left !important;
            margin-bottom: 5px;

            @media (min-width: breakpoints.$screen-sm-min) {
                margin-top: 30px;
                font-size: 30px;
            }
        }

        &__membership {
            font-size: 16px;
        }

        &__features {
            padding: 20px;

            ul {
                margin: 0 !important;
                columns: 2;

                @media (max-width: breakpoints.$screen-sm-min) {
                    columns: 1;
                }

                li {
                    border: 0 !important;
                }
            }
        }
    }

    .plan-selector {
        background-color: #E9E9E9;
        border-radius: 10px 10px 0 0;
        min-height: 60px;
        display: flex;
        position: relative;
        margin: 24px 0 0;

        li {
            flex: 1;
            position: relative;
        }

        .item {
            height: 60px;
            width: 100%;
            border-radius: 10px;
            border: none;
            background-color: transparent;
            cursor: pointer;
            font-size: 18px;
            line-height: 18px;
            font-weight: 700;
            transition: background-color 200ms, color 200ms;
            padding: 0;

            span {
                font-size: 16px;
                font-weight: 400;
            }

            &:not(.active) {
                &:hover {
                    background-color: rgba(238, 238, 238, .8);
                }
            }

            &.active {
                background-color: #555555;
                color: #F3F3F3;
            }

            @media (min-width: breakpoints.$screen-sm-min) {
                font-size: 20px;
                line-height: 22px;

                span {
                    font-size: 18px;
                }
            }
        }

        &.baby-premium {
            background: url('../img/red-circles-illustration.svg') right -610px top 250px no-repeat;

            @media (min-width: breakpoints.$screen-sm-min) {
                background-position: right -450px top -540px;
            }

            .plan-box h1 {
                padding-bottom: 20px;
                font-size: 24px;

                @media (min-width: breakpoints.$screen-md-min) {
                    font-size: 26px;
                }
            }
        }
    }

    .price-container {
        background: #F6F6F6;
        position: relative;
        text-align: center;

        p, div {
            font-size: 30px;
            display: inline-block;
            vertical-align: middle;
        }

        .price {
            font-size: 70px;
        }

        @media(min-width: 375px) {
            .price {
                font-size: 110px;
            }
        }
    }

    .popular-tip {
        background-color: var(--mp-red);
        font-size: 13px;
        white-space: nowrap;
        text-align: center;
        border-radius: 12px 12px 0 0;
        height: 24px;
        line-height: 24px;
        color: #FFF;
        font-weight: 400;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding: 0 12px;

        @media (min-width: breakpoints.$screen-md-min) {
            padding: 0 17px;
        }

        &__single-package {
            background-color: var(--mp-red);
            font-size: 13px;
            white-space: nowrap;
            text-align: center;
            border-radius: 12px 12px 0 0;
            height: 24px;
            line-height: 30px;
            color: #FFF;
            position: absolute;
            padding: 0 12px;

            @media (max-width: breakpoints.$screen-sm-min) {
                line-height: 24px;
                position: relative;
                transform: none;
                left: 0;
                bottom: 0;
                border-radius: 5px;
                width: 150px;
                margin: auto;
                font-weight: 600;
            }

            @media (min-width: breakpoints.$screen-md-min) {
                background: none;
                width: calc((100% / 3) - 20px);
                margin-top: -85px;

                span {
                    background-color: #E00C00;
                    padding: 0 12px;
                    border-radius: 12px 12px 0 0;
                    font-weight: 400 !important;
                }
            }
        }
    }

    .mini-terms {
        margin-top: 20px;

        @media(min-width: breakpoints.$screen-sm-min) {
            margin-top: 60px;
        }
    }
}

.promo {
    max-width: 600px;
    padding: 0 2rem;
    margin: 6rem auto 0;
}

.big-title {
    font-size: 20px;
    text-align: center;
}

.big-title button {
    color: #E00C00;
    text-decoration: underline;
    margin-left: 4px;
    background: transparent;
    border: 0;
    cursor: pointer;
}

.promo-form {
    padding: 20px 0;
}

.promo-form > div {
    position: relative;
    margin: auto;
    width: 100%;
}

.promo-form > div > div {
    font-size: 12px;
    margin-top: 4px;
}

.promo-form input {
    border: 1px solid #BBB;
    border-radius: 4px;
    font-size: 16px;
    height: 50px;
    padding: 0 40px 0 14px;
    width: 100%;
}

.promo-form input[type='text'] {
    color: var(--mp-main-dark);
}

.promo-form button {
    background: transparent;
    border: none;
    text-decoration: underline;
    position: absolute;
    right: 5px;
    height: 50px;
}

.promo-form button > .spin-loader {
    display: none;
}

.promo-form button.loading > .spin-loader {
    display: block;
    padding-top: 3px;
}
.promo-form button.loading > div {
    display: none;
}

.promo-form {
    border: none;
    outline: none;
    background-color: transparent;
    color: #E00C00;
    right: 10px;
    top: 0;
}

.payment-sucess {
    padding: 30px 15px;
    text-align: center;
}

@media (min-width: 768px) {
    .promo-form > div {
        max-width: 320px;
    }
}

.title-row {
    @media (min-width: breakpoints.$screen-sm-min) {
        display: flex;
        align-items: center;
    }
}

.featuring-sbp {
    dt {
        font-size: 20px;
        line-height: 24px;
        margin: 0 0 6px;

        @media (min-width: breakpoints.$screen-sm-min) {
            font-size: 22px;
            line-height: 27px;
            margin: 0 0 2px;
        }
    }

    dd {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 22px;

        @media (min-width: breakpoints.$screen-sm-min) {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 20px;
        }
    }
}
