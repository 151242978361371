@use 'breakpoints' as breakpoints;
@use 'mixins' as mixins;

.modal.modal-loading {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1600;

    loading {
        margin: auto;
    }
}

.payment-form {
    .lg {
        font-size: 18px;
    }

    .sm {
        font-size: 14px;
    }

    .after-coupon {
        margin-top: 10px;
    }
}

.payment-iframe {
    z-index: 1;
    position: relative;
    padding-top: 15px;
}

.payment-wrapper {
    position: relative;
}

.iframe-loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 460px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .iframe-loader {
        @include mixins.loader(40px);
    }

    @media (max-width: breakpoints.$screen-xs-max) {
        width: 100%;
        height: 480px;
    }
}

.international {
    margin-left: 1em;
}

.payment__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;

    &__description {
        font-size: 22px;
        font-weight: normal;
        line-height: 1.36;
    }

    &__price {
        font-size: 26px;
        font-weight: 700;
        line-height: 1.23;
        margin-top: 10px;
        font-family: var(--font-family-title);
    }

    &__conditions {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.63;
        letter-spacing: 0.17px;
        color: #222222;
    }
}

.no-left-margin {
    @media (min-width: breakpoints.$screen-sm-min) {
        margin-left: -15px;
    }
}

ul.tablist-radio-lookalike {
    margin: 30px 0;
    li {
        width: 100%;
        display: block;
        position: relative;
        margin-top: -1px;

        > button {
            height: 60px;
            width: 100%;
            background: transparent;
            border: 1px solid #ccc;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: left;
            padding: 5px 15px;

            &[disabled] {
                opacity: 0.4;
            }

            .radio {
                @include mixins.square(22px);
                @include mixins.border-radius(50%);
                background-color: #FFF;
                border: 1px solid #999;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                flex: none;

                > div {
                    transition: all 0.2s linear;
                    @include mixins.square(0);
                    @include mixins.border-radius(50%);
                    background-color: var(--mp-red);
                }
            }

            > div, ul.cards-flag {
                display: flex;
                align-items: center;
            }

            > ul.cards-flag {
                img {
                    height: 22px;
                    width: auto;
                    margin-left: 4px;
                }
            }
        }

        &:hover {
            > button {
                border-color: #999;
                background-color: #EDEDED;
            }
        }

        &.active {
            z-index: 1;

            > button {
                border-color: var(--mp-red);
                @include mixins.border-radius(4px 4px 0 0);
                background-color: #EDEDED;

                .radio {
                    > div {
                        @include mixins.square(12px);
                    }
                }
            }
        }

        &:first-child {
            > button {
                @include mixins.border-radius(4px 4px 0 0);
            }
        }
        &:last-child {
            > button {
                @include mixins.border-radius(0 0 4px 4px);
            }
        }
    }
}

.international-card-selector {
    margin-bottom: 30px;
}

h4.testimonials-title {
    font-family: var(--font-family-title);
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;

    @media (min-width: breakpoints.$screen-md-min) {
        margin: 40px 0;
    }
}

.cvv-tooltip-btn {
    position: absolute;
    top: -4px;
    left: 38px;
    border: none;
    background-color: transparent;
}

.payment-checkout {
    .additional-information-block {
        margin-top: 20px;
        margin-bottom: 10px;
    }
}

.btn.boleto-url {
    background-color: transparent;
    border: 2px solid var(--mp-red);
    color: var(--mp-red);

    &:focus {
        color: var(--mp-red);
    }

    &:hover {
        background-color: var(--mp-red);
        color: #FFF;
    }

    span {
        font-size: 19px;
        vertical-align: bottom;
    }
}
