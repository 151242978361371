@use 'breakpoints' as breakpoints;

.melt-toast {
    display: flex;
    align-items: center;
    background-color: #FEF0DC;
    margin: -16px;
    padding: 25px 16px;

    @media (max-width: breakpoints.$screen-sm-max) {
        margin: -23px;
    }

    &__close {
        color: #FFF;
        background-color: transparent;
        border: 0;
        position: absolute;
        right: 8px;
        top: 0;
        z-index: 1;

        span {
            font-size: 22px;
        }
    }

    &__caramel {
        position: absolute;
        right: 0;
        top: 0;
    }

    &__images {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 24px;

        &__avatar {
            position: relative;
            width: 44px;
            height: 44px;
            margin-right: 10px;
            background: #777;
            border: solid 2px #EE6361;
            border-radius: 100%;
        }

        :nth-child(1) {
            z-index: 2;
        }

        :nth-child(2) {
            margin-left: -22px;
            z-index: 1;
        }
    }

    &__infos {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &__logo {
            position: relative;

            &::before {
                content: '';
                background-image: url('../img/ic_favorite.svg');
                background-size: 11px 20px;
                position: absolute;
                display: inline-block;
                width: 11px;
                height: 20px;
                right: -20px;
                bottom: 0;
            }

            &::after {
                content: '';
                background-image: url('../img/ic_favorite.svg');
                background-size: 11px 20px;
                position: absolute;
                display: inline-block;
                width: 11px;
                height: 20px;
                right: -32px;
                bottom: 7px;
            }
        }

        &__title {
            font-size: 15px;
            font-weight: bold;
            line-height: 1.33;
            letter-spacing: normal;
            color: #DD802D;
            margin-top: 2px;
        }
    }
}
