.slidable-menu-panel {
    overflow-x: hidden;
}

.slidable-menu-backdrop {
    background-color: rgba(0, 0, 0, .7);
}

.slidable-menu-bar {
    width: 290px;
    white-space: nowrap;
    background: #FFF;
    min-height: 100%;
    padding: 25px 15px 0;
    position: relative;

    &__user-header {
        display: flex;
        justify-content: space-between;
    }

    &__plans-button {
        padding: 11px 0 20px;
    }

    &__user-buttons {
        padding-bottom: 29px;
    }

    &__actions {
        padding-bottom: 100px;
        .menu-separator {
            border-top: 1px solid #DDD;
            padding-top: 20px;
            margin-left: -25px;
            margin-right: -25px;
        }
    }

    &__bottom {
        position: absolute;
        bottom: 40px;
        left: 50%;
        width: 100%;
        transform: translate(-50%);
        display: flex;
        flex-direction: column;
    }
}
