@use 'breakpoints' as breakpoints;
@use 'mixins' as mixins;

.outside-page.container {
    @media (max-width: breakpoints.$screen-sm-max) {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .row {
        display: flex;

        @media(max-width: breakpoints.$screen-xs-max) {
            justify-content: center;
        }
    }
}

.banner-container {
    position: static;
    padding: 0;

    .login-banner {
        background-size: cover;
        position: absolute;
        z-index: 1;
        top: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @media(min-width: breakpoints.$screen-md-min) {
            right: 0;
            height: 100vh;
        }

        @media(min-width: breakpoints.$screen-md-min) and (max-width: breakpoints.$screen-md-max) {
            width: 40%;
        }

        @media(min-width: breakpoints.$screen-lg-min) {
            width: 49%;
        }

        @media(max-width: breakpoints.$screen-md-min) {
            width: 100vw;
            height: 45%;
            left: 0;
            background-position: 0 27%;
        }
    }
}

.card-container {
    z-index: 2;

    .business-info {
        margin-top: 30px;
    }
}

.login-card {
    background-color: #FFF;
    @include mixins.border-radius(4px);
    @include mixins.boxShadow(0, 0, 3px, 0, 0, 0, 0, 0.16);
    padding: 20px;
    height: auto;

    @media(min-width: breakpoints.$screen-sm-min) {
        width: 100%;
    }

    @media(max-width: breakpoints.$screen-xs-max) {
        min-width: 290px;
        max-width: 456px;
    }

    &__mp-logo {
        margin-bottom: 27px;

        img {
            width: auto;
            height: 20px;
            object-fit: unset;
        }
    }

    &__register-text {
        font-size: 14px;
        letter-spacing: 0.17px;
        margin: 0;
        padding-right: 50px;
    }

    &__register-action {
        color: var(--mp-red);
        font-size: 15px;
        font-weight: 700;
        padding-right: 26px;
        text-decoration: underline;

        &:hover {
            color: var(--mp-red);
        }
    }
}

form {
    .form-action {
        margin: 30px 0;

        &__forget-pass {
            a {
                display: flex;
                color: #333;
                font-size: 12px;
                padding: 0;
                text-decoration: underline;
                cursor: pointer;

                &:hover {
                    color: #333;
                    text-decoration: underline;
                }
            }
        }

        &__login-button {
            flex: 1;
            padding-left: 20px;
        }
    }
}
