@use 'breakpoints' as breakpoints;
@use 'mixins' as mixins;
@use 'placeholders' as *;

h1.conversation {
    font-size: 26px;
    line-height: 32px;
    margin: 0;
    padding: 20px 0 18px 20px;
}

@mixin conversation-container {
    width: 940px;

    @media (min-width: breakpoints.$screen-lg-min) {
        width: 1140px;
    }

    @media (min-width: breakpoints.$screen-xl-min) {
        width: 1420px;
    }
}

@media (min-width: breakpoints.$screen-md-min) {
    body.in-messages {
        padding-bottom: 0;
    }

    .conversation-bg {
        position: fixed;
        background: #222;
        left: 0;
        right: 0;
        top: 70px;
        height: 240px;
    }

    .conversation-wrapper {
        position: relative;
        top: 20px;
        left: 0;
        right: 0;
        height: calc(100vh - 140px);
        margin: 0 auto;
        border-radius: 4px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        @include conversation-container;
    }
}

.messages-list {
    width: 100%;
    height: 170px;

    @media (min-width: breakpoints.$screen-md-min) {
        position: relative;
        width: auto;
        height: calc(100% - 110px);
        top: auto;
        bottom: auto;
        margin: 0;
    }
}

.conversation-block {
    display: block;
    width: 100%;
    height: calc(100% - 110px);
}

.expired-message {
    display: block;
    padding: 31px 0 81px;
    text-align: center;
    font-size: 13px;

    @media (min-width: breakpoints.$screen-md-min) {
        padding: 31px 0;
    }
}

.message-item-wrapper {
    min-height: 80px;
    position: relative;
    cursor: pointer;

    .dot {
        &--is-online {
            color: #4FD463;
            position: absolute;
            z-index: 5;
            right: 0;
            top: 0;
            border: 1px solid var(--currentConversation);
            border-radius: 30px;
            background-color: var(--currentConversation);
            font-size: 10px;
        }

        &--unread-message {
            color: var(--mp-red);
            font-size: 10px;
            margin-left: auto;
            visibility: hidden;
            position: absolute;
            right: 10px;
            top: 36px;

            &.unread {
                visibility: visible;
            }
        }
    }

    .message-preview,
    .message-dropdown {
        position: relative;
        width: 100%;
    }

    .message-preview {
        z-index: 4;
        height: 80px;
        padding: 12px 10px;
        display: flex;
        background-color: var(--white);
        left: 0;
        transition: 0.2s ease-out;

        &__photo {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 14px;
            outline: 0;

            img.avatar {
                top: 0;
                width: 50px;
                height: 50px;
                position: relative;
                left: 0;

                @include mixins.border-radius(50%);
            }

            .user-badge {
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 8;
                border-radius: 50px;
                width: 17px;
            }
        }

        &__info {
            width: calc(100% - 64px);
            outline: 0;

            p {
                margin: 0;
            }

            .flex {
                .location,
                .message-time {
                    font-size: 12px;
                    color: #999;
                }

                .favorite-heart-fill {
                    color: var(--mp-red);
                    font-size: 16px;
                    margin-right: 3px;
                }

                .username {
                    flex: 1;
                    font-size: 15px;
                    font-weight: 700;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .message-time {
                    margin-left: auto;
                    visibility: visible;
                }

                .location {
                    align-self: flex-start;
                }

                .message {
                    display: contents;

                    .read-flag {
                        color: #999;
                        font-size: 18px;
                        margin-right: 4px;

                        &.visualized {
                            color: #589BF8;
                        }
                    }

                    p {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 13px;
                    }
                }
            }
        }

        .dropdown-toggle {
            position: absolute;
            top: 24px;
            right: 15px;
            font-size: 28px;
            display: none;
        }

        &.current,
        & ~ .message-dropdown {
            background-color: var(--currentConversation);
        }
    }

    .message-dropdown {
        z-index: 3;
        height: 0;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding-right: 10px;
        overflow: hidden;
        transition: 0.3s;

        &.open {
            height: 130px;
        }

        li {
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 1;

            i {
                font-size: 20px;
                padding-left: 5px;
            }

            &:hover {
                background-color: var(--hoverConversation);
            }
        }

        &__delete {
            color: var(--mp-red);
        }

        &__favorite {
            font-weight: bold;

            i {
                color: var(--mp-red);
            }
        }
    }

    .message-swipe {
        width: 100%;
        height: 80px;
        z-index: 2;
        position: absolute;
        top: 0;
        justify-content: flex-end;
        color: var(--white);
        background-color: var(--white);

        &.userUnavailable{
            background-color: var(--mp-red);

            .favorite {
                display: none;
            }
        }

        i {
            font-size: 30px;
        }

        .delete-button,
        .favorite {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .delete-button {
            padding: 0 15px;
            background-color: var(--mp-red);
            width: 50px;
            color: var(--white);
        }

        .favorite {
            width: 90px;

            .favorited,
            .notfavorited {
                position: absolute;
            }

            .favorited {
                background: -webkit-linear-gradient(var(--mp-red), #c00e00);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .notfavorited {
                color: var(--mp-main-dark);
            }

            &.isLiked {
                .favorited {
                    animation: dropIn 0.2s;
                }

                .notfavorited {
                    animation: dropOut 0.2s;
                    opacity: 0;
                }
            }

            &.notLiked {
                .favorited {
                    animation: dropOut 0.2s;
                    opacity: 0;
                }

                .notfavorited {
                    animation: dropIn 0.2s;
                }
            }
        }
    }

    @media (min-width: breakpoints.$screen-md-min) {
        &:hover {
            .dot--unread-message,
            .message-preview .message-time {
                visibility: hidden;
            }

            .message-preview {
                background-color: var(--hoverConversation);
            }

            .dropdown-toggle {
                display: block;
            }
        }
    }


    @media (max-width: breakpoints.$screen-sm-max) {
        width: 100%;

        .message-preview {
            position: absolute;
            top: 0;
            background-color: var(--secondary-soft);

            &:hover {
                background-color: var(--secondary-soft);

                .dropdown-toggle {
                    display: none;
                }
            }
        }

        .message-dropdown,
        .dropdown-toggle {
            display: none;
        }
    }
}

.side-col {
    &.invisible {
        display: none;
        margin-top: 0;
    }

    @media (max-width: breakpoints.$screen-md-min) {
        margin-top: 44px;
    }

    @media (min-width: breakpoints.$screen-md-min) {
        width: 375px;
        height: 100%;
        border-radius: 4px 0 0 4px;
        overflow: hidden;
        flex-shrink: 0;
    }
}

.chat-col {
    min-width: 0;

    @media (min-width: breakpoints.$screen-md-min) {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 10px 10px 10px 0;
        flex: 1;
        padding: 10px;
        align-self: stretch;
        border-radius: 4px;
        background-color: var(--currentConversation);
    }
}

.preview {
    position: fixed;
    z-index: 101;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-out;
    height: 375px;

    @include conversation-container;

    &.hovering {
        opacity: 1;
    }

    img {
        border: 5px solid var(--currentConversation);
        border-radius: 16px;
        box-shadow: 0 0 44px 10px rgba(0, 0, 0, 0.2);
        width: 275px;
        height: 275px;
        background-color: #000;
        margin-left: 285px;
    }
}

.connection-message {
    padding: 4px;
    background-color: var(--mp-red);
    font-weight: 700;
    text-align: center;
    color: var(--white);
    font-size: 12px;
}

.placeholder-area {
    text-align: center;
    max-width: 500px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: breakpoints.$screen-sm-max) {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        max-width: none;
    }

    .main-icon {
        height: 100px;
        width: auto;
        margin: 0 auto;
    }

    h1 {
        @extend %montserrat-placeholder;
        font-size: 23px;
        font-weight: 700;

        @media (min-width: breakpoints.$screen-sm-min) {
            font-size: 30px;
        }
    }

    p {
        font-size: 18px;
    }

    a {
        display: inline-block;
        font-size: 18px;
        font-weight: 700;
        border-radius: 4px;
        width: 290px;
        padding: 12px;
    }
}


@keyframes dropIn {
    0% {
        font-size: 0;
    }

    100% {
        font-size: 30px;
    }
}

@keyframes dropOut {
    from {
        font-size: 30px;
        opacity: 1;
    }

    to {
        font-size: 40px;
        opacity: 0;
    }
}
