@use 'breakpoints' as breakpoints;
@use 'mixins' as mixins;

.verify-photo {
    border-radius: 12px;
    background: #FFF;
    color: #333;
    padding: 20px;
    text-align: left;
    margin: 20px 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.05);

    @media (max-width: breakpoints.$screen-sm-max) {
        position: relative;
        z-index: 1;
    }

    h2 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        margin: 0 0 8px;
    }

    p {
        font-size: 16px;
        line-height: 22px;
        margin: 0 0 18px;
    }

    .profile-badge.badge-verified {
        margin-left: 0;
    }

    a {
        display: block;
        max-width: 272px;
        background: var(--mp-red);
        color: #FFF;
        padding: 8px;
        font-weight: 700;
        border-radius: 4px;
        margin: 0 auto 10px;
        text-decoration: none;
    }

    .hide-btn {
        display: block;
        background: transparent;
        color: inherit;
        border: 0;
        margin: 15px auto;
        padding: 0;
        text-decoration: underline;
    }

    button.mp-main-button {
        font-size: 16px;
    }
}

.half-page-banner.verify-banner {
    @media (max-width: breakpoints.$screen-sm-max) {
        @include mixins.aspect-ratio(3.75px, 3px);
    }
}

.half-page-content.verify-content {
    .verify-description {
        margin-bottom: 29px;
        font-size: 18px;
    }

    .profile-badge.badge-verified {
        @media (min-width: breakpoints.$screen-md-min) {
            margin-top: 20px;
        }
    }

    .buttons-container {
        margin-bottom: 30px;
    }

    .button.mp-main-button {
        font-size: 16px;
        width: 100%;

        @media(min-width: breakpoints.$screen-md-min) {
            width: 315px;
        }
    }

    .imge-block {
        @media(max-width: breakpoints.$screen-md-min) {
            text-align: center;
        }
    }

    .img-sample {
        width: 100%;
        height: 100%;
        margin-top: 20px;
        margin-bottom: 7px;

        @media(min-width: breakpoints.$screen-md-min) {
            width: 200px;
            height: 200px;
        }
    }

    .outline-red {
        @media(max-width: breakpoints.$screen-md-min) {
            margin: 0 auto;
            display: block;
        }
    }
}

loading {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.warning-title {
    text-transform: uppercase;
}

.verify-modal {
    text-align: center;

    @media(min-width: breakpoints.$screen-sm-min) {
        text-align: left;
    }

    &.dial-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 750px !important;

        @media(min-width: breakpoints.$screen-sm-min) {
            justify-content: space-between;
        }
    }

    .header-text {
        font-size: 30px;
        margin-top: 15px;
    }

    .waiting-image {
        max-width: 370px;
    }

    .sidebar-img {
        img {
            width: 100%;
            border-radius: 4px;

            @media(min-width: breakpoints.$screen-sm-min) {
                width: 250px;
                height: 250px;
            }
        }
    }

    .close-button {
        background: transparent;
        border: 0;
        color: #BBB;
        font-size: 25px;
        line-height: 1;
        padding: 0;
        position: absolute;
        right: 20px;
        top: 20px;

        i {
            display: block;
        }
    }

    h2 {
        font-size: 18px;
        font-family: var(--font-family-title);
        font-weight: 700;
    }

    .buttons-container {
        padding-top: 0;
    }

    .button.mp-main-button {
        font-size: 16px;
        max-width: 315px;
        margin-bottom: 27px;
    }
}

.file-input {
    position: relative;
    overflow: hidden;

    &:focus-within {
        box-shadow: 0 0 0 4px var(--mp-red-lighter);
    }

    input[type="file"] {
        position: absolute;
        left: -200%;
        top: 0;
        opacity: 0.001;
        width: 300%;
        height: 100%;
        cursor: pointer;
    }
}
