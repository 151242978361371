.boost-multiplier-tiny {
    font-family: var(--font-family-title);
    font-size: 14px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;

    &__ratio {
        color: var(--boost-purple);
        font-weight: 700;
        display: flex;
        align-items: center;
        margin-right: 4px;

        i {
            font-size: 14px;
            display: block;
            margin-left: -2px;
            margin-bottom: -1px;
            margin-right: -2px;
        }
    }

    &__label {
        font-weight: bold;
    }
}
