@use 'mixins' as mixins;

.registration-form-field {
    position: relative;

    > * {
        display: block;
    }

    label {
        font-weight: 700;
        font-size: 14px;
        margin-bottom: -5px;

        .material-icons.help {
            display: initial;
            margin: -8px 0 0 6px;
            padding: 0;
            font-size: 24px;
            line-height: normal;
            position: absolute;
            cursor: pointer;
        }
    }

    input, select, textarea {
        border: none;
        border-bottom: 1px solid #CCC;
        width: 100%;
        height: 40px;
        padding: 0;
        outline: none;
        @include mixins.border-radius(0);

        &.ng-invalid.ng-touched {
            border-bottom: 1px solid var(--mp-red);
        }

        &:focus {
            border-bottom: 1px solid #000;
        }

        &[disabled] {
            opacity: 0.5;
        }
    }

    select {
        @include mixins.appearance-off();
        background: url('../img/chevron-down.svg') right center no-repeat;
        background-size: 10px auto;
    }

    small {
        display: none;

        &.error {
            color: var(--mp-red);
        }

        &.warning {
            display: block;
            color: inherit;
        }

        &.ok {
            color: #0B3;
        }

        &.email-suggestion {
            font-style: italic;
            color: #0077CA;
        }
    }

    &__birthdate {
        display: flex;
        justify-content: space-between;

        select {
            width: 30%;
        }
    }

    small {
        display: block;
    }

    .validation-loader {
        @include mixins.loader(20px);

        & {
          position: absolute;
          right: 0;
          top: 26px;
        }
    }
}
