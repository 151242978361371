.boost-progress {
    &__description {
        font-size: 16px;
        font-weight: 800;
        padding-bottom: 10px;
    }

    &__bar {
        background: #DDD;
        height: 18px;
        width: 100%;
        border-radius: 17px;
        padding: 3px;
        display: flex;
        overflow-x: hidden;
        white-space: nowrap;

        &__progress {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            background-image: linear-gradient(to right, #4400bb 42%, #AA00EE);
            height: 12px;
            min-width: 12px;
            width: 100%;
            border-radius: 6px;
            transition: width .6s ease-in-out;
            transition-delay: 1s;

            .material-icons {
                color: #FFF;
                font-size: 11px;
                margin-right: 2px;
            }
        }
    }
}
