@use 'mixins' as mixins;

.reactivate-modal-box {
    overflow: hidden;
    width: 100%;
    height: 270px;
    background-color: #FFF;
    margin: auto;
    position: relative;
    text-align: center;

    max-width: 400px;
    padding: 0 15px;
    display: flex;
    align-items: center;

    @include mixins.border-radius(6px);

    > div {
        @include mixins.transition(opacity, 1s, ease-out);
        width: 100%;
        visibility: hidden;
        display: none;
        opacity: 0;

        &.active {
            visibility: visible;
            display: block;
            opacity: 1;
        }

        &.ng-animating {
            visibility: hidden;
            display: block;
            opacity: 0;
        }
    }

    .actions > * {
        margin-top: 20px;
    }

    p.title {
        font-weight: bold;
        font-size: 28px;
        line-height: 1.2em;
        margin-bottom: 15px;
    }

    .description {
        font-size: 18px;
        font-weight: inherit;
    }

    .mp-main-button {
        width: 280px;
    }

    .cancel {
        text-decoration: underline;
        cursor: pointer;
        outline: none;
        color: #000;
    }

    .loader-container {
        position: relative;
        height: 60px;

        .lds-css {
            margin-top: -10px;
        }
    }
}
