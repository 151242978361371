@use 'breakpoints' as breakpoints;

.check-container {
    position: relative;
}

input.custom[type="checkbox"] {
    position: absolute;
    opacity: 0.01;
    width: 4em;
    height: 1.85em;
    left: 0;
    top: 0;
    margin: 0;
    cursor: pointer;
    z-index: 1;

    & + label {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;

        .text {
            flex-grow: 1;
        }

        &::before,
        &:after {
            content: '';
        }

        &::before {
            width: 4em;
            margin-right: 0.5em;
            height: 1.85em;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 1em;
            transition: all .4s;
            flex: none;
        }

        &::after {
            position: absolute;
            width: 1.4em;
            height: 1.4em;
            transition: all .4s;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.2);
            top: 50%;
            transform: translateY(-50%);
            left: 0.24em;
        }
    }

    &:checked {
        & + label {
            &::before {
                background: rgba(var(--mp-red), 0.2);
            }
            &::after {
                background: var(--mp-red);
                transform: translateY(-50%) translateX(2.16em);
            }
        }
    }

    &[disabled] {
        & + label {
            opacity: 0.4;
        }
    }

    &.focus-visible:focus {
        & + label {
            &::before {
                box-shadow: 0 0 0 2px var(--mp-red);
            }
        }
    }
}

.options-checklist {
    margin: 30px 0 60px;

    > li {
        position: relative;
        margin: 20px 0;

        .check-container {
            @media (max-width: breakpoints.$screen-sm-max) {
                .custom {
                    left: auto;
                    right: 0;
                }
            }

            .custom + label {
                margin-bottom: 0;

                @media (max-width: breakpoints.$screen-sm-max) {
                    flex-direction: row-reverse;

                    &::before {
                        margin-right: 0;
                        margin-left: 0.5em;
                    }

                    &::after {
                        left: auto;
                        right: 2.4em;
                    }
                }
            }
        }
    }
}
