@use 'breakpoints' as breakpoints;
@use 'mixins' as mixins;

.submenu-list {
    position: fixed;
    left: 0;
    top: 57px;
    width: 100%;
    padding-bottom: 14px;
    z-index: 99;
    background-color: #222;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .05);
    overflow-y: hidden;
    overflow-x: scroll;
    scrollbar-width: none;

    @media(max-width: breakpoints.$screen-md-min) {
        top: 51px;
    }

    @media(min-width: breakpoints.$screen-md-min) {
        top: 70px;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &__navigation-list {
        border-bottom: 0;
        display: flex;
        flex-flow: row nowrap;
        margin-bottom: 0;

        @media(max-width: breakpoints.$screen-md-min) {
            padding: 0 14px;
        }
    }

    &__item {
        font-size: 14px;
        background-color: #333;
        @include mixins.border-radius(15px);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 10px;
        margin-right: 5px;

        .material-icons {
            line-height: 0;
        }

        @media(max-width: breakpoints.$screen-md-min) {
            padding: 8px 10px;
            font-size: 12px;
        }

        a {
            color: #FFF;
            cursor: pointer;
            text-decoration: none;
            display: flex;
            align-items: center;
            white-space: nowrap;
            line-height: 1;
        }

        &.active {
            background-color: var(--mp-red);
            font-weight: 700;
            cursor: pointer;

            .submenu-list__action {
                &.refresh-icon {
                    margin-left: 5px;

                    i {
                        font-size: 20px;
                    }
                }
            }
        }

        &:last-child {
            margin-right: 0;
        }

        &:not(.active) {
            a.refresh-icon {
                display: none;
            }
        }
    }
}

.submenu-v2.conversation {
    margin-bottom: 25px;

    .submenu-list {
        position: fixed;
        padding-bottom: 14px;

        a.refresh-icon {
            display: none;
        }

        @media(min-width: breakpoints.$screen-md-min) {
            background-color: unset;
            box-shadow: none;
            position: static;

            &__navigation-list {
                width: auto;
            }

            &__item {
                background-color: #F5F5F5;

                &.active {
                    background-color: #E00C00;

                    .submenu-list--action {
                        color: #FFF;
                    }
                }
            }

            &--action {
                color: #222;
            }
        }
    }
}

.animate-spin {
    animation: spin 1s linear infinite;
}

.native-app {
    @media(max-width: breakpoints.$screen-sm-max) {
        top: 41px;
        padding-top: 8px;
    }
}
