.badges-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 10px;
}

.sbp-badge {
    background: linear-gradient(to right, #FF4D43, #E00C00);
    color: #FFF;
    font-weight: 700;

    img {
        width: 16px;
        margin: 4px;
    }
}

.melt-badge {
    background-color: unset;
    padding: 0;

    img {
        width: auto;
        margin: 0;
    }
}

.elite-badge {
    font-weight: 700;
    background: linear-gradient(to right, #F06600, #FF9D00);
    color: #FFF;

    img {
        height: 12px;
        width: auto;
        margin: -3px 6px 0 4px;
    }
}

.boost-extended-badge {
    img {
        width: 9px;
        margin: 0 9px;
    }

    p {
        color: var(--boost-purple);
        font-weight: bold;
    }
}

.profile-badge {
    p {
        margin-top: -1px;
        margin-bottom: 0;
    }
}
