@use 'breakpoints' as breakpoints;

.boost-payment-modal {
    width: 100%;

    @media (min-width: breakpoints.$screen-sm-min) {
        width: 420px;
    }

    &__form {
        margin-top: 30px;
    }
}
