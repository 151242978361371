%montserrat-placeholder {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
}

%opensans-placeholder {
  font-family: 'OpenSans', Arial, Helvetica, sans-serif;
}

%btn-no-styles {
  background-color: transparent;
  border: none;
  padding: 0;
}
