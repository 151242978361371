.boost-multiplier {
    font-family: var(--font-family-title);
    font-weight: 700;

    &__ratio {
        color: #5D00FF;
        font-size: 30px;
        font-weight: 700;
        line-height: 37px;
        display: flex;
        align-items: center;

        i {
            font-size: 27px;
            display: block;
            margin-left: -4px;
        }
    }

    &__label {
        font-weight: bold;
        line-height: 19px;
    }
}
