@use 'breakpoints' as breakpoints;

.flipdown {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 0 30px;

    @media(max-width: breakpoints.$screen-xbs-min) {
        padding: 0;
    }

    @media(max-width: 550px) {
        width: 100%;
    }

    @media(min-width: breakpoints.$screen-sm-min) {
        padding: 0 30px;
        width: 500px;
        margin: auto;
    }
}

.flipdown.flipdown__theme-mp .rotor-group {
    float: none;
    display: flex;
}

.flipdown.flipdown__theme-mp .rotor-group .rotor {
    float: none;
}

.flipdown.flipdown__theme-mp {
    font-family: var(--font-family-title);
    font-weight: bold;
}

.flipdown.flipdown__theme-mp .rotor-group-heading:before {
    color: #FFF;
}

.flipdown.flipdown__theme-mp .rotor-group:nth-child(n+2):nth-child(-n+3):before,
.flipdown.flipdown__theme-mp .rotor-group:nth-child(n+2):nth-child(-n+3):after {
    display: none;
}

.flipdown.flipdown__theme-mp .rotor,
.flipdown.flipdown__theme-mp .rotor-top,
.flipdown.flipdown__theme-mp .rotor-leaf-front {
    color: #FFFFFF;
    background-color: #C00E00;
}

.flipdown.flipdown__theme-mp .rotor-bottom,
.flipdown.flipdown__theme-mp .rotor-leaf-rear {
    color: #EFEFEF;
    background-color: var(--mp-red);
}

.flipdown .rotor-group:nth-child(1) .rotor-group-heading {
    display: none;
}

.flipdown .rotor-group:nth-child(2) .rotor-group-heading {
    display: none;
}

.flipdown .rotor-group:nth-child(3) .rotor-group-heading {
    display: none;
}

.flipdown .rotor-group:nth-child(4) .rotor-group-heading {
    display: none;
}

.flipdown .rotor {
    margin: 0 2px 0 0;
}

.flipdown .rotor-group {
    padding-right: 0;
}

@media (max-width: 555px) {

    .flipdown {
        display: flex;
        justify-content: center;
        padding: 0;
    }

    .flipdown .rotor-group {
        padding-right: 6px;
    }
}
