@use 'breakpoints' as breakpoints;

.modal-view {
    position: fixed;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;

    @media (min-width: breakpoints.$screen-sm-min) {
        display: flex;
        justify-content: center;
        align-items: center;

        &__pointer-events {
            pointer-events: none !important;
        }
    }

    &__backdrop {
        background-color: rgba(0, 0, 0, 0.7);
    }

    &__container {
        position: relative;
        background-color: #FFF;
        z-index: 1;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 26px 25px;

        &--with-header {
            padding-top: 0;
        }

        @media (min-width: breakpoints.$screen-sm-min) {
            border-radius: 4px;
            height: unset;
            max-height: calc(100% - 30px);
        }
    }

    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 50px;
        margin-bottom: 4px;

        img {
            margin-left: -8px;
            cursor: pointer;
        }
    }

    &__footer {
        margin-top: 25px;
    }

    &__header,
    &__content,
    &__footer {
        @media (min-width: breakpoints.$screen-sm-min) {
            pointer-events: all;
        }
    }
}
