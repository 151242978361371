@use 'breakpoints' as breakpoints;
@use 'mixins' as mixins;

.mp-modal-backdrop {
    background-color: rgba(0, 0, 0, .9);
}

.mp-modal-content {
    overflow: auto;
}

.new-modal {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .9);
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 9999;

    @media (min-width: 768px) {
        padding: 50px;
    }
}

.new-modal-box {
    width: 100%;
    min-height: 100px;
    background-color: #FFF;
    margin: auto;
    position: relative;

    @media (min-width: breakpoints.$screen-sm-min) {
        border-radius: 12px;
        max-width: 600px;
    }

    &.full-img-bg {
        text-align: center;
        color: #FFF;
        padding: 100px 15px;
        background: #000 url('../img/modal-img.jpg') center center no-repeat;
        background-size: cover;
        position: relative;
        width: calc(100% - 30px);

        @media (min-width: breakpoints.$screen-sm-min) {
            width: 100%;
        }
    }

    .title {
        font-weight: 700;
    }

    .description {
        margin: 20px 0;
    }
}

.new-modal-main-header {
    text-align: center;
    padding: 15px;
    position: relative;

    &.absolute {
        position: absolute;
        height: 50px;
        padding: 0;
        top: 0;
        left: 0;
        right: 0;
        margin-top: env(safe-area-inset-top);
        margin-left: env(safe-area-inset-left);
        margin-right: env(safe-area-inset-right);

        @media (min-width: 768px) {
            margin: 0;
        }
    }

    p {
        margin: 0;
    }

    button {
        background-color: transparent;
    }

    .modal-close {
        width: 50px;
        height: 50px;
        border: none;

        img {
            border: none;
        }
    }

    .right-btn {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        right: 0;
    }
}

.new-modal-box.full-img-bg p.title {
    max-width: 420px;
    margin: auto;
    font-size: 28px;
    line-height: 1.2em;
}

.line-options {
    text-align: center;

    p {
        font-size: 13px;
        margin-top: 5px;
    }

    > * {
        display: block;
        margin: 8px;

        @media (min-width: 768px) {
            display: inline-block;
            vertical-align: middle;
            margin: 8px;
        }
    }

    > div a, label {
        display: inline-block;
        background-color: #E00C00;
        font-size: 18px;
        font-weight: 700;
        color: #FFF;
        text-decoration: none;
        padding: 13px 15px;
        margin-bottom: 0;
        border-radius: 6px;
        width: 220px;

        &.off {
            background-color: #CCC;
        }

        @media (min-width: 768px) {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 0 15px;
            border-radius: 6px;
            width: 220px;
            height: 50px;
            line-height: 1.15;
        }
    }
}

#orTohide {
    height: 50px;
    vertical-align: top;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.ten-message-wrapper {
    padding: 100px 10px;
}

.ten-message-frame {
    background: #FFF;
    color: #000;
    border-radius: 6px;
    margin: 0 auto;
    max-width: 700px;
    padding: 0 20px 30px;
    text-align: center;

    > * {
        max-width: 575px;
        margin-left: auto;
        margin-right: auto;
    }

    .avatar-header {
        margin-top: -40px;
        position: relative;

        img {
            border: 5px solid #FFF;
            border-radius: 100%;
            vertical-align: top;

            &.avatar {
                width: 110px;
                height: 110px;
                background: #000;
            }

            &.icon-badge {
                position: absolute;
                top: 70px;
                width: 54px;
                height: 54px;
            }

            &.elite {
                left: 50%;
                margin-left: 17px;
            }

            &.premium {
                right: 50%;
                margin-right: 17px;
            }
        }
    }

    h2 {
        font-weight: 700;
        font-size: 20px;
        margin-top: 25px;
        margin-bottom: 6px;
    }

    p {
        font-size: 18px;
    }

    .btn-upgrade {
        display: block;
        background: var(--mp-red);
        color: #FFF;
        width: 260px;
        font-weight: 700;
        margin: 50px auto 10px;
    }

    .btn-close {
        color: #000 !important;
        text-decoration: underline;
    }
}

.free-msg-modal-box {
    font-size: 18px;
    text-align: center;
    border-radius: 12px;
    max-width: 600px;
    background: #FFF;
    position: relative;
    overflow: hidden;

    .loader-modal {
        @include mixins.loader(2em);

        & {
          margin: 10px auto !important;
        }
    }

    button {
        background-color: transparent;
    }

    .modal-close {
        width: 50px;
        height: 50px;
        border: none;

        img {
            border: none;
        }
    }

    .right-btn {
        position: absolute;
        top: 0;
        right: 0;
    }

    a {
        display: inline-block;
        background-color: #E00C00;
        font-size: 16px;
        font-weight: 700;
        color: #FFF;
        text-decoration: none;
        padding: 11px 45px;
        border-radius: 8px;
        margin-top: 12px;
    }

    &-main-header {
        span {
            font-weight: 600;
            font-size: 14px;
            display: block;
            margin: 18px 0;
        }

        div {
            margin: 0 20px 10px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &-main-content {
        color: #FFF;
        font-weight: bold;
        padding: 22px 30px;
        background: #000 url('../img/modal-img.jpg') center center no-repeat;
        background-size: cover;

        p {
            margin: 20px 0;
        }
    }
}
