@use 'sass:math';

@mixin remove-appearance {
    appearance: none;
}

@mixin remove-button-appearance {
    @include remove-appearance();
    border: 0;
    outline: unset;
    padding: 0;
    background-color: unset;
}

@mixin add-border-radius($length) {
    border-radius: $length;
}

@mixin box-shadow($horizontal, $vertical, $blur, $spread, $RR, $GG, $BB, $AA) {
    box-shadow: $horizontal $vertical $blur $spread rgba($RR, $GG, $BB, $AA);
}

@mixin disable-text-selection() {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin loader($size: 40px, $speed: .5s) {
  border-radius: 100%;
  width: $size;
  height: $size;
  margin: 0 auto;
  position: relative;
  text-indent: -9999em;
  color: rgba(255, 255, 255, 0.2) !important;
  border: math.div($size, 10) solid;
  border-left-color: var(--mp-red);
  transform: translateZ(0);
  animation: load8 $speed infinite linear;
  overflow: hidden;

  @-webkit-keyframes load8 {
      0% {
          transform: rotate(0);
      }
      100% {
          transform: rotate(360deg);
      }
  }
  @keyframes load8 {
      0% {
          transform: rotate(0);
      }
      100% {
          transform: rotate(360deg);
      }
  }
}

@mixin can-hover {
    @media (hover: hover) and (pointer: fine) {
        @content;
    }
}

@mixin border-radius($length) {
    border-radius: $length;
}

@mixin mp-select-field {
    @include remove-appearance();
    width: 100%;
    height: 44px;
    background: #FFF url('/assets/img/chevron-down.svg') right center no-repeat;
    background-size: 10px auto;
    background-position: top 50% right;
    border: 1px solid transparent;
    outline: 0;
    padding: 10px 0;
    margin-top: 2px;
    margin-bottom: 20px;
    color: #404040;
    font-size: 14px;
    border-bottom: 1px solid #EAEAEA;

    &.option-selected {
        border-color: #1BFEB5;
    }
}

@mixin inline-loader(
    $width: 25px,
    $height: 25px,
    $top: -3px,
    $bottom: 0,
    $left: -3px,
    $right: 0,
) {
    position: relative;
    width: $width;
    height: $height;

    &:before {
        content: "";
        position: absolute;
        top: $top;
        bottom: $bottom;
        left: $left;
        right: $right;
        width: $width;
        height: $height;
        border: 3px solid transparent;
        border-top: 3px solid var(--mp-red);
        border-left: 3px solid var(--mp-red);
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
}

@mixin remove-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}

@mixin size-h3() {
  font-size: 22px;
  line-height: 27px;
  font-family: var(--font-family-title);
  font-weight: 700;
  margin: 0;
}

@mixin size-h4() {
  font-size: 18px;
  line-height: 22px;
  font-family: var(--font-family-title);
  font-weight: 700;
  margin: 0;
}

@mixin transition($attr, $time, $ease: 'linear') {
  transition: $attr $time $ease;
}

@mixin appearance-off {
  appearance: none;
}

@mixin aspect-ratio($width, $height, $addToBottom: 0px) {
  $result: math.div($height, $width);
  position: relative;
  &:before {
      display: block;
      content: " ";
      width: 100%;
      padding-top: calc( #{$result} * 100% + #{$addToBottom});
  }

  > .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
  }
}

@mixin boxShadow($horizontal, $vertical, $blur, $spread, $RR, $GG, $BB, $AA) {
  box-shadow: $horizontal $vertical $blur $spread rgba($RR, $GG, $BB, $AA);
}

@mixin square($length) {
  width: $length;
  height: $length;
}

@mixin truncate-singleline($width) {
  max-width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

@mixin getArrowUp($size, $color) {
  width: 0;
  height: 0;
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-bottom: $size solid $color;
}

@mixin getArrowLeft($size, $color) {
  width: 0;
  height: 0;
  border-bottom: 8px solid transparent;
  border-right: 8px solid $color;
  border-top: 8px solid transparent;
}
