.menu-actions {
    &--item {
        display: block;
        padding: 15px 0;
    }

    &--link {
        color: #222;
        font-size: 14px;
        line-height: 1.36;
        text-align: left;
        text-decoration: none;
        cursor: pointer;

        &:active, &:hover, &:focus {
            color: #222;
            text-decoration: none;
        }
    }
}
