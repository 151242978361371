.boost-activated-card {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
    background: #FFF;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    width: 100%;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;

        img {
            align-self: flex-start;
        }

        &__title-block {
            h3 {
                font-family: var(--font-family-title);
                font-size: 22px;
                font-weight: 800;
                line-height: 1.2;
                letter-spacing: 0.3px;
                margin: 0;
            }
        }

        .boost-icon {
            width: 0;
            height: 0;
            transition: width .6s ease-in-out, height .6s ease-in-out;

            &--show-up {
                width: 30px;
                height: 30px;
                transition: width .6s ease-in-out, height .6s ease-in-out;
            }
        }
    }

    &__body {
        width: 100%;
        margin-bottom: 21px;

        &__boost-description {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20px 0 30px;
        }
    }
}
