.custom-radio-check {
    [type="checkbox"]:checked, [type="radio"]:checked,
    [type="checkbox"]:not(:checked), [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type="checkbox"]:checked + label, [type="radio"]:checked + label,
    [type="checkbox"]:not(:checked) + label, [type="radio"]:not(:checked) + label
    {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 22px;
        display: inline-block;
        color: #666;
    }
    [type="checkbox"]:checked + label:before, [type="radio"]:checked + label:before,
    [type="checkbox"]:not(:checked) + label:before, [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 22px;
        height: 22px;
        border: 1px solid #BBB;
        border-radius: 100%;
        background: #FFF;
    }
    [type="checkbox"]:checked + label::after, [type="radio"]:checked + label::after,
    [type="checkbox"]:not(:checked) + label::after, [type="radio"]:not(:checked) + label::after {
        content: '';
        width: 12px;
        height: 12px;
        background: #E00C00;
        position: absolute;
        top: 5px;
        left: 5px;

        border-radius: 50%;
        transition: all 0.2s linear;
    }

    [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label::after, [type="checkbox"]:not(:checked) + label::after {
        border-radius: 0;
    }

    [type="checkbox"]:not(:checked) + label::after, [type="radio"]:not(:checked) + label::after {
        opacity: 0;
        transform: scale(0);
    }
    [type="checkbox"]:checked + label::after, [type="radio"]:checked + label::after {
        opacity: 1;
        transform: scale(1);
    }
}
