@use 'breakpoints' as breakpoints;

div {
    &.settings {
        text-align: right;
        font-size: 11px;
    }
}

.private-photo-tab-content {
    @media (max-width: breakpoints.$screen-sm-max) {
        margin-top: 50px;
    }
}

.new-feature {
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 18px;
    font-size: 11px;
    line-height: 16px;
    padding: 0 3px;
    color: var(--mp-red);
    border: 1px solid;
    background: #FFF;
    box-shadow: 0 0 10px 0 rgba(224, 12, 0, 0.7);
}
