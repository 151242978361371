.boost-toast {
    display: flex;

    &__images {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 24px;

        &__avatar {
            position: relative;
            width: 44px;
            height: 44px;
            margin-right: 10px;
            background: #777;
            border: 2px solid #FFF;
            border-radius: 100%;
        }

        :nth-child(1) {
            z-index: 2;
        }

        :nth-child(2) {
            margin-left: -22px;
            z-index: 1;
        }
    }

    &__infos {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &__title {
            font-size: 15px;
            font-weight: bold;
            line-height: 1.33;
            letter-spacing: normal;
            color: #222222;
        }

        &__subtitle {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;

            &__text {
                font-weight: normal;
                line-height: 1.36;
                margin-left: 4px;
                color: #222222;
            }
        }

        &__views {
            margin-top: 7px;
        }
    }
}
