mp-actionbar-profile-details {
    width: calc(100% - 100px);
}

.isOnMobileApp {
    margin-left: 20px;
}

mp-actionbar-page-title, mp-actionbar-back-title {
    width: 100%;
}

.actioonbar-profile-edit {
    justify-content: space-between;
}
