@use 'breakpoints' as breakpoints;
@use 'mixins' as mixins;

.payment-banner {
    position: fixed;
    height: 100%;
    width: 40%;
    left: 60%;
    top: 0;
    background: black url('../img/outside-payment.jpg') center center no-repeat;
    background-size: cover;

    @media (min-width: breakpoints.$screen-lg-min) {
        width: 50%;
        left: 50%;
    }
}

input.card-check {
    margin-right: 8px;
}

.outside-page {
    h1 {
        font-family: "Montserrat", "Open Sans";
        font-weight: 700;
        font-size: 26px;

        @media(min-width: breakpoints.$screen-md-min) {
            font-size: 28px;
        }
    }

    .page-menu {
        @media(max-width: breakpoints.$screen-sm-max) {
            padding-top: 0;
            margin-top: 0;
            height: auto;
            background-color: transparent;
            border: none;
            @include mixins.boxShadow(0, 0, 0, 0, 0, 0, 0, 0);

            ul {
                display: flex;
                justify-content: center;
            }

            ul li {
                width: auto;
                padding: 0 15px;
                height: 36px;
                margin: 9px 5px 12px;
                a {
                    border: none;
                    color: var(--mp-main-dark);
                }

                &.active {
                    @include mixins.border-radius(20px);
                }
            }
        }
    }
}

.payment-margin {
    margin-top: 15px;
}

.close-outside {
    font-size: 13px;
    margin-top: .5em;
    display: flex;
    align-items: center;
    i {
        font-size: 16px;
        margin-left: 2px;
    }
    color: var(--mp-main-dark);
}
