@use 'breakpoints' as breakpoints;

.notifications-modal {
    text-align: center;

    &.dial-box {
        position: relative;
        display: flex;
        align-items: center;
        max-width: 750px;
        padding-bottom: 40px;
    }

    .header-text {
        font-size: 30px;
        margin-top: 15px;
    }

    .notifications-description {
       margin-left: 80px;
       margin-right: 80px;

       @media (max-width: breakpoints.$screen-sm-min) {
           margin-left: 0;
           margin-right: 0;
       }
    }

    .curve {
        margin: 20px auto 19px;
    }

    .notifications-gifs {
        width: 100%;
        max-width: 538px;
        height: auto;
        padding-top: 30px;
        padding-bottom: 40px;
    }

    .close-button {
        background: transparent;
        border: 0;
        color: #BBB;
        font-size: 25px;
        line-height: 1;
        padding: 0;
        position: absolute;
        right: 20px;
        top: 20px;

        i {
            display: block;
        }
    }
}
