@use 'breakpoints' as breakpoints;

.error-404 {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000 url('../img/404-s.jpg') center no-repeat;
    @supports (background-image: url('../img/404-s.webp')) {
        background: #000 url('../img/404-s.webp') center no-repeat;
    }

    &.full {
      top: 0 !important;
      bottom: 0 !important;
    }

    @media (min-width: breakpoints.$screen-xs-min) {
        background-image: url('../img/404-xl.jpg');
        @supports (background-image: url('../img/404-xl.webp')) {
            background-image: url('../img/404-xl.webp');
        }
    }

    @media (min-width: breakpoints.$screen-md-min) {
        top: 70px;
        bottom: 0;
    }
}

.error-404-inner {
    max-width: 520px;
    margin: 0 15px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #fff;

    .logo {
        margin: 0 0 46px;

        img {
            height: 28px;
        }
    }

    .title {
        font-weight: 400;
        margin: 0 0 6px;
    }

    .blurb {
        margin: 0 0 30px;
    }

    .back-to-start {
        font-size: 16px;
        line-height: 22px;
        border-radius: 4px;
        background: #e00c00;
        color: #ffffff;
        display: inline-block;
        padding: 11px 32px;
    }
}

.content-maintenance {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000 url('../img/404-s.jpg') center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: breakpoints.$screen-xs-min) {
        background-image: url('../img/404-xl.jpg');
    }

    @media (min-width: breakpoints.$screen-md-min) {
        bottom: 0;
    }

    &.full {
        top: 0 !important;
        bottom: 0 !important;
    }
}

.content-maintenence-inner {
    max-width: 520px;
    margin: 0 15px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #fff;

    .logo {
        margin: 0 0 46px;

        img {
            height: 28px;
        }
    }

    .title {
        margin: 0 0 6px;
    }

    .blurb {
        font-weight: 400;
        margin: 0 0 30px;
    }

    .update-page {
        font-size: 16px;
        line-height: 22px;
        border-radius: 4px;
        background: #e00c00;
        color: #ffffff;
        display: inline-block;
        padding: 11px 60px;
        cursor: pointer;
    }
}
