@use 'breakpoints' as breakpoints;

.notification-page {
    .outside-banner {
        position: fixed;
        height: 100%;
        width: 40%;
        left: 60%;
        top: 0;

        @media (min-width: breakpoints.$screen-md-min){
            &.payment {
                background: black url('../img/outside-payment.jpg') center center no-repeat;
                background-size: cover;
            }
            &.new-pass {
                background: black url('../img/new-pass-banner.jpg') center center no-repeat;
                background-size: cover;
            }
        }

        @media (min-width: breakpoints.$screen-lg-min) {
            width: 50%;
            left: 50%;
        }
    }

    .red-link {
        color: #E00C00;
    }

    .main-button {
        margin-top: 20px;
        padding: 20px 16px;
        width: 100%;
        background-color: var(--mp-red);
        color: #FFF;

        &:hover {
            opacity: .8;
            color: #FFF;
        }

        &:hover {
            opacity: 1;
            color: #FFF;
            outline: none;
        }
    }
}

emailchangesuccess {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: #FFFFFF;
}
