@use 'breakpoints' as breakpoints;
@use 'mixins' as mixins;

.outside-body {
    padding-top: 30px;
    position: absolute;
    width: 100%;
    top: 0;
    min-height: 100%;
    padding-bottom: 50px;

    @media(min-width: breakpoints.$screen-sm-min) {
        padding-top: 40px;
    }

    @media(min-width: breakpoints.$screen-md-min) {
        padding-top: 50px;
    }

    &.express {
        @media(max-width: breakpoints.$screen-xs-max) {
            @media(min-resolution: 1dppx), (-webkit-min-device-pixel-ratio: 1) {
                background: black url('../img/express-main-s.jpg') top center no-repeat;
                background-size: 100% auto;
            }

            @media(min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
                background: black url('../img/express-main-s@2x.jpg') top center no-repeat;
                background-size: 100% auto;
            }
        }

        @media(min-width: breakpoints.$screen-sm-min) {
            background: black url('../img/express-main-xl@2x.jpg') top center no-repeat;
            background-size: 1280px auto;
        }
    }
}

.text-white {
    color: #FFF;
}

.outside-page {
    .generic-header {
        display: none !important;
    }

    .header {
        img {
            width: 180px;
            height: auto;
            object-fit: unset;
            @media(min-width: breakpoints.$screen-md-min) {
                width: 250px;
            }
        }
    }

    .flex-header {
        display: flex;
        justify-content: space-between;

        .logout-btn-container {
            button.btn-link {
                color: #333;
                text-decoration: underline;
            }
        }
    }

    .nagbar-space {
        margin-top: 30px;
    }
}

.express-link {
    display: block;
    width: 100%;
    height: 70px;
    color: #FFF;
    background-color: var(--mp-red);
    border: 0;
    font-weight: 700;

    @include mixins.border-radius(6px);
    cursor: pointer;

    &.outline {
        background-color: transparent;
        border: 1px solid white;
    }

    &:hover {
        color: #FFF;
    }

    &.active {
        color: inherit;
    }

    img {
        width: 36px;
        height: 36px;
    }

    p {
        margin: 0 0 0 8px;
        font-size: 18px;
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}

.express-link-container {
    background-color: #FFF;
    @include mixins.border-radius(20px);
    margin-top: 20px;

    h1 {
        margin-top: 0;
        margin-bottom: 8px;
    }

    padding: 20px;

    @media(min-width: breakpoints.$screen-sm-min) {
        padding: 30px;
    }
}

.logout-btn-container {
    text-align: right;
    padding: 10px 0;
    font-size: 12px;

    button.btn-link {
        color: #FFF;
    }
}

expressapprovalmodal {
    .modal {
        display: block;
        background-color: rgba(0, 0, 0, 0.8);
    }

    .modal-content,
    .modal-body {
        padding: 10px;

        @media (min-width: breakpoints.$screen-sm-min) {
            padding: 10px 20px;
        }
    }

    .terms {
        margin-bottom: 15px;
        text-align: justify;
    }

    .radio-label {
        margin-left: 10px;
    }

    .flex {
        @media (max-width: breakpoints.$screen-xs-max) {
            flex-direction: column;
            align-items: flex-start;
        }

        @media (min-width: breakpoints.$screen-sm-min) {
            > :first-child {
                margin-right: 25px;
            }
        }
    }
}

.outside-banner.express {
    position: fixed;
    height: 100%;
    width: 40%;
    left: 60%;
    top: 0;
    @media (min-width: breakpoints.$screen-sm-min){
        background: #000 url('../img/express-bg.jpg') center center no-repeat;
        background-size: cover;
        width: 50%;
        left: 50%;
    }
}
