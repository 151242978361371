.boost-activatable {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
    max-width: 500px;
    margin: auto;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;

        img {
            align-self: flex-start;
        }

        .boost-icon {
            width: 0;
            height: 0;
            transition: width .6s ease-in-out, height .6s ease-in-out;

            &--show-up {
                width: 30px;
                height: 30px;
                transition: width .6s ease-in-out, height .6s ease-in-out;
            }
        }
    }

    &__body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin-top: 10px;

        &__buttons {
            width: 100%;
            margin-top: 10px;

            .mp-main-button.bt-md {
                margin-top: 27px;
                margin-bottom: 20px;
            }

            &--buy-more {
                text-decoration: underline;
                color: #222;
                font-size: 16px;
                display: block;
                text-align: center;
                cursor: pointer;

                &:active {
                    color: #222;
                }

                &:hover {
                    color: #222;
                }
            }
        }
    }
}
