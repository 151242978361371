.membership-badge {
    .user-badge {
        margin-right: -11px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid #222;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    &__premium-badge {
        background: linear-gradient(to right, #FF4D43, #E00C00);

        img {
            width: 16px;
            height: 16px;
        }
    }

    &__elite-badge {
        background: linear-gradient(to right, #F06600, #FF9D00);

        img {
            width: 12px;
            height: 12px;
            margin-top: -2px;
        }
    }
}

.mp-subscribed-status .membership-badge {
    cursor: pointer;
}
