.boost-package-selection {
    display: flex;
    color: #222;

    .custom-radio-check {
        &:hover {
            [type='checkbox']:checked + label:before, [type='radio']:checked + label:before,
            [type='checkbox']:not(:checked) + label:before, [type='radio']:not(:checked) + label:before {
                border: 1px solid var(--boost-purple);
            }
        }

        [type='checkbox']:checked + label::after, [type='radio']:checked + label::after,
        [type='checkbox']:not(:checked) + label::after, [type='radio']:not(:checked) + label::after {
            background: var(--boost-purple);
        }

        input.focus-visible[type='checkbox']:focus + label:before,
        input.focus-visible[type='radio']:focus + label:before {
            box-shadow: 0 0 0 2px var(--boost-purple);
        }
    }

    &__item-option {
        padding-bottom: 10px;

        &__radio {
            height: 22px;
            width: 22px;
        }
    }

    &__option-list {
        $option-list: &;

        display: flex;
        width: 100%;
        flex-flow: column nowrap;

        &__popular-tip {
            color: #FFF;
            background: var(--boost-purple);
            padding: 2px 0 1px;
            border-radius: 12px 12px 0 0;
            white-space: nowrap;
            line-height: 24px;
            font-size: 13px;
            opacity: 1;
            margin-top: -27px;
            cursor: pointer;

            @media (max-width: 350px) {
                font-size: 11px;
            }
        }

        &__option {
            width: 100%;
            border-radius: 4px;
            border: 1px solid #DDD;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            padding: 10px 0;
            cursor: pointer;
            opacity: 0.5;
            transition: .2s opacity ease-in-out;

            &--selected {
                border: 3px solid var(--boost-purple);
                opacity: 1;
                box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.2);
            }

            &__title, &__name {
                margin: 0;
            }

            &__title {
                font-family: var(--font-family-title);
                font-weight: 700;
                font-size: 32px;
            }

            &__name {
                font-family: var(--font-family-title);
                font-weight: 700;
                font-size: 18px;
                margin-top: -2px;
            }

            &__price {
                display: flex;
                flex-direction: column;
                margin-top: -3px;

                &__label {
                    display: block;
                    font-size: 18px;
                    font-weight: 400;
                }

                &__discount {
                    font-size: 16px;
                    color: var(--boost-purple-ligther);
                    line-height: 1.44;
                }

                #{$option-list}:first-child & {
                    flex-direction: column-reverse;

                    &__discount {
                        visibility: hidden;
                    }
                }
            }
        }

        &:not(:last-child) {
            margin-right: 6px;
        }
    }
}
