@use 'breakpoints' as breakpoints;
@use 'mixins' as mixins;

.trial-modal {
    text-align: center;

    .title {
        font-size: 24px;
        margin-top: 20px;

        .medium {
            display: block;
            width: 250px;
            margin: 0 auto;

            @media (min-width: breakpoints.$screen-md-min) {
                width: auto;
                font-size: 30px;
            }
        }

        .large {
            display: block;
            font-size: 32px;

            @media (min-width: breakpoints.$screen-md-min) {
                font-size: 36px;
            }
        }
    }

    .mp-main-button {
        max-width: 260px;
    }

    .fine-print {
        margin: 0px auto 20px;
        font-size: 12px;
        line-height: 17px;
        max-width: 430px;

        small {
            font: inherit;
        }
    }

    .cancel-print {
        margin: 20px auto 0;
        font-size: 12px;
        line-height: 17px;
        max-width: 430px;
        small {
            font: inherit;
        }
    }

    .countdown-time {
        background-color: #000;
        @include mixins.border-radius(4px);
        margin-right: -20px;
        margin-left: -20px;
        margin-top: -20px;
        font-family: var(--font-family-title);
        font-weight: 700;

        .countdown-title {
            color: #FFF;
            font-size: 18px;
            font-weight: 700;
            padding: 17px 0;
        }

        .board-group {
            padding-bottom: 20px;

            .flipdown-text-block {
                margin-top: -15px;
                color: #FFF;
                display: flex;
                justify-content: space-around;
                text-align: center;
                width: 500px;
                padding: 0 30px 0;

                @media(max-width: 550px) {
                    justify-content: center;
                    width: 100%;
                    font-size: 14px;
                    padding: 0;

                    .flipdown-text:nth-of-type(2), .flipdown-text:nth-of-type(3) {
                        padding-left: 4px;
                    }

                    .flipdown-text:nth-of-type(4) {
                        padding-left: 8px;
                    }
                }

                .flipdown-text {
                    width: 104px;

                    @media(max-width: 550px) {
                        width: 61px;
                        flex-wrap: nowrap;
                        display: flex;
                    }
                }
            }
        }
    }
}
