@use 'mixins' as mixins;

.mini-users-list {
    margin-left: -15px;
    margin-right: -15px;

    .conversation-item-container {
        height: 80px;
    }

    > li, .private-photos {
        position: relative;
        padding: 0 15px;

        &:last-child {
            border-bottom: none;
        }

        a {
            display: block;
            position: relative;
            cursor: pointer;
            border-bottom: 1px solid #DEDEDE;
        }

        .info {
            padding: 15px 0;
            height: 70px;
            color: var(--mp-main-dark);
            display: flex;
            align-items: center;

            .username {
                font-weight: 700;
                @include mixins.truncate-singleline(calc(100%));
                margin: 0;
            }

            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 10px;
            }
        }

        .float-dropdown {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 43px;
            right: 0;

            button.btn.dropdown-toggle {
                display: flex;
                align-items: center;
                height: 43px;
                background-color: transparent;
                border: none;
            }

            ul.dropdown-menu {
                width: auto;
                right: 10px;
                left: auto;

                i {
                    vertical-align: middle;
                    font-size: 18px;
                    margin-right: 10px;
                }
            }
        }
    }
}
