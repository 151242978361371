@use 'placeholders' as *;

.user-button {
    display: flex;
    flex-flow: row nowrap;
    @extend %btn-no-styles;
    margin-bottom: 23px;

    &.referral {
        margin-bottom: 0;
    }

    &__boost {
        width: 100%;
        position: relative;
    }

    &--icon {
        align-self: center;
        height: 25px;
    }

    &--button-content {
        text-align: left;
        margin-left: 9px;

        .title {
            font-weight: 700;
            font-size: 16px;
        }

        .description {
            font-size: 14px;

            span {
                font-weight: 400;
            }
        }
    }

    .new-feature {
        align-self: center;
        position: absolute;
        right: 0;
    }
}
