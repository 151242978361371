@use 'breakpoints' as breakpoints;

.sbp-nagbar {
    border-radius: 12px;
    background: #FFF url('../img/red-circles-illustration.svg') bottom -40px right -760px no-repeat;
    color: #333;
    margin: 10px 0;
    padding: 25px 30px;
    text-align: center;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.05);
    min-height: 230px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: breakpoints.$screen-sm-min) {
        background-position: bottom -40px right -560px;
    }

    h2 {
        font-size: 25px;
        line-height: 24px;
        font-weight: 700;
        margin: 0 -25px 5px;

        @media (min-width: breakpoints.$screen-sm-min) {
            font-size: 36px;
            line-height: 44px;
        }
    }

    p {
        font-size: 18px;
        line-height: 24px;
        margin: 0 -10px 18px;
        max-width: 560px;

        @media (min-width: breakpoints.$screen-sm-min) {
            font-size: 20px;
            line-height: 27px;
        }
    }

    .mp-main-button {
        margin: 0 auto;
        max-width: 260px;
        width: 100%;
        border-width: 2px !important;
    }
}
