.boost-balance {
    font-family: var(--font-family-title);
    line-height: 27px;
    font-weight: 700;

    > div p {
        margin: 0;
    }

    &__label {
        margin-bottom: 0;
        font-size: 22px;
        line-height: 27px;
    }

    &__balance {
        color: var(--boost-purple);
        font-size: 22px;
    }
}
