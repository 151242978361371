@keyframes slideInFromRight {
	0% {
		transform: translateX(100%);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@media (prefers-reduced-motion: reduce) {
	@keyframes slideInFromRight {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
}

@keyframes slideInFromLeft {
	0% {
		transform: translateX(-100%);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@media (prefers-reduced-motion: reduce) {
	@keyframes slideInFromLeft {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes gradientAnimation {
	0% {
		background-position: 0 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0 50%;
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
