.boost-bubbles {
    &__bubbles {
        display: flex;
        padding-right: 12px;
        flex-direction: row-reverse;

        img {
            width: 36px;
            height: 36px;
            margin-right: -12px;
            border: 1px solid #FFF;
            border-radius: 100%;
            position: relative;

            &:nth-child(1) {
                z-index: 5;
            }

            &:nth-child(2) {
                z-index: 4;
            }

            &:nth-child(3) {
                z-index: 3;
            }

            &:nth-child(4) {
                z-index: 2;
            }

            &:nth-child(5) {
                z-index: 1;
            }
        }
    }
}
