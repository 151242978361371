.two-boost-credits {
    font-size: 22px;
    font-weight: 500;
    height: 30px;
    width: 70px;
    border-radius: 30px;
    display: inline-block;
    line-height: 30px;
    text-align: left;
    padding-left: 10px;
    box-shadow: 2px 2px 2px #222;
    background: right url('../img/boost-icon.svg') no-repeat #555;
    background-size: contain;
}
