mp-subscribed-status {
    z-index: 100;
}

.subscribed-status {
    margin-left: 20px;

    &.extended {
        margin: 11px 0 36px;
    }
}
