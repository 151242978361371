@tailwind base;
@tailwind components;
@tailwind utilities;

// Overwrites some of Tailwind's CSS reset, keeping compatibility to our current CSS.
@layer base {
  h1 {
    @apply tw-font-bold;
    @apply tw-text-4xl;
    @apply sm:tw-text-5xl;
    @apply tw-mt-5;
    @apply tw-mb-5;
  }

  h2 {
    @apply tw-font-bold;
    @apply tw-mt-10;
    @apply tw-mb-3;
  }

  h3, h4 {
    @apply tw-font-bold;
    @apply tw-mt-2;
    font-size: 18px;
  }

  img {
    max-width: unset;
  }
}
