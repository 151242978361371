@use 'sass:math';
@use 'breakpoints' as breakpoints;
@use 'mixins' as mixins;

ul {
    padding: 0;

    li {
        list-style: none;
    }
}

.padding-top15px {
    padding-top: 15px;
}

.flex {
    display: flex;
    align-items: center;
}

span {
    font-weight: 700;
}

.bold {
    font-weight: 700;
}

.linebottom {
    border-bottom: 1px solid #DEDEDE;
    padding-bottom: 10px;
}

.no-padding {
    padding: 0!important;
}

.no-margin {
    margin: 0!important;
}

.wid-50perc {
    width: 50%;
}

.blur-filter {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.generic-list {
    li {
        padding: 15px 20px 15px 0;
        border-bottom: 1px solid #DEDEDE;
        background: transparent url('../img/right-chevron.svg') right center no-repeat;
        background-size: 14px;
        position: relative;

        a {
            color: var(--mp-main-dark);
            font-size: 18px;
            display: block;

            .new-feature {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    li:last-child {
        border-bottom: none;
    }
}

.generic-header {
    padding: 0;
    color: #FFF;
    width: 100%;
    height: 64px;
    background: #222;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;

    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        margin: 0;
        width: 100%;

        > * {
            pointer-events: auto;
        }

        > div:first-child {
            display: flex;
            align-items: center;
        }
    }

    a {
        color: #FFF;
    }

    i {
        text-shadow: 0 0 2px #000;
    }

    .flex {
        color: #FFF;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0;

        &.flex-full {
            width: 100%;
        }
    }

    .more-actions {
        position: relative;
        z-index: 1;
        background-color: var(--mp-main-dark);

        @media (min-width: breakpoints.$screen-md-min) {
            background-color: transparent;
        }
    }

    .avatar {
        @include mixins.square(30px);
        @include mixins.border-radius(50%);
        background-color: #999;
    }

    .username {
        padding-left: 50px;
        @include mixins.truncate-singleline(calc(100% - 10px));
    }

    .dropdown {
        width: 100%;
    }

    #headerDropdown {
        background-color: transparent;
        color: #FFF;
        border: none;
        width: 50px;
        padding: 0;
        display: flex;
        justify-content: center;
    }

    .dropdown-menu {
        right: 0;
        text-align: right;
        left: auto;
        width: auto;

        li {
            a {
                padding: 10px 20px;
            }
        }

        li a.dropdown-menu--option-content {
            padding: 16px 20px;
        }

        &__text-action {
            font-weight: 400;
        }

        &__icon {
            font-size: 19px;
            text-shadow: none;
            padding-left: 5px;
        }

        &--option-list {
            width: 240px;
        }

        &--option-content {
            display: flex;
            justify-content: flex-end;
        }

        &--alert {
            color: var(--mp-red);
        }

        &--strong {
            font-weight: 700;
        }
    }

    .text {
        font-weight: 700;
        margin: 0;
        font-size: 15px;
        @include mixins.truncate-singleline(calc(100% - 10px));
    }
}

.action-bar {
    width: 100vw;
}

form.page-form {
    margin-top: 15px;

    button {
        background-color: var(--mp-red);
        width: 100%;
        color: #FFF;

        &:not(:disabled):hover, &:focus {
            color: #FFF;
            opacity: .8;
        }
    }
}

textarea {
    resize: none;
    width: 100%;
}

.inertial {
    -webkit-overflow-scrolling: touch;
}

.btn.bt-backtooldversion {
    margin: 20px auto 0;
    display: block;
    background-color: #BBB;
    color: #FFF;
}

.cdk-overlay-container {
    z-index: 1600;
}
