@use 'breakpoints' as breakpoints;

.boost-results {
    width: 100%;

    @media (min-width: breakpoints.$screen-sm-min) {
        max-width: 414px;
    }

    &__title {
        font-family: var(--font-family-title);
        font-size: 26px;
        color: #222;
        font-weight: bold;
        margin-top: 0;
    }

    &__cycle {
        margin-bottom: 17px;

        &__label {
            font-family: var(--font-family-title);
            font-size: 18px;
            margin-bottom: 0;
        }

        &__duration {
            font-size: 18px;
        }
    }

    &__result_column {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 37px;
    }

    &__balance {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 16px;

        &__icon {
            width: 30px;
            height: 30px;
        }
    }

    &__cta {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 41px;
        height: 43px;

        i {
            margin-left: 4px;
            font-size: 28px;
        }
    }

    &__secondary-content {
        &__title {
            font-family: var(--font-family-title);
            font-size: 18px;
            line-height: 22px;
        }

        p,
        a {
            font-weight: 400;
            font-size: 16px;
            cursor: pointer;
        }

        &__cta {
            display: block;
            text-decoration: underline;
            color: #222;
            margin-top: 2.5rem;
        }
    }
}
