.skeleton-item {
    height: 1em;
    background-color: #D9D9D9;
    background-image: linear-gradient(to right, transparent 0px, rgba(0,0,0,0.05) 25px, transparent 50px);
    background-position: -50px 0;
    background-size: 50px 100%;
    background-repeat: no-repeat;
    animation: bg-move 2s linear infinite;

    &-lighter {
        background-color: #E3E3E3;
    }

    &-invert {
        background-color: rgba(255, 255, 255, 0.1);
        background-image: linear-gradient(to right, transparent 0px, rgba(255,255,255,0.05) 25px, transparent 50px);
    }

    &-square {
        width: 1em;
    }

    &-rounded {
        border-radius: 0.2em;
    }

    &-pill {
        border-radius: 1em;
    }
}

@keyframes bg-move {
    to {
        background-position: 1000px 0;
    }
}
